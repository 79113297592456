<template>
  <div>
    <div class="alert">
      <div class="header">气象灾害预警信号</div>
      <div class="body">
        <div class="empty">
          江苏省气象台{{
            provinceList.length > 0 ? provinceList.length + "个" : "无"
          }}预警
        </div>
        <div class="empty">
          全省市县{{ cityList.length > 0 ? cityList.length + "个" : "无" }}预警
        </div>
      </div>
    </div>

    <div class="swiper alert-swiper" v-show="provinceList.length > 0">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in provinceList"
          :key="index"
        >
          <div class="title">
            {{ `江苏省气象台发布${item.alarmType}${item.alarmLevel}预警` }}
          </div>
          <div style="display: flex; align-items: flex-start">
            <img
              :src="`https://adm.ac.cn/static/img/warning/${item.alarmType}_${item.alarmLevel}.png`"
              alt=""
            />
            <div class="content">{{ item.issueContent }}</div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapState("alert", ["list"]),
    ...mapGetters("alert", ["provinceList", "cityList"]),
  },

  mounted() {
    this.getList();

    new window.Swiper(".alert-swiper", {
      spaceBetween: 8,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },

  methods: {
    ...mapActions("alert", ["getList"]),
  },
};
</script>

<style lang="less" scoped>
.alert {
  border: 1px solid #f8c168;
  background: #fff;
  margin-bottom: 12px;

  .header {
    color: #e44e35;
    background: #fff2c8;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
  }

  .body {
    line-height: 30px;

    .empty {
      color: #e78474;
      border-bottom: 1px solid #fff5e5;
      text-align: center;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.alert-swiper {
  width: 325px;
  background: #fff7e0;
  border: 1px solid #dfdfdf;
  line-height: 24px;
  box-sizing: border-box;

  .swiper-slide {
    padding: 12px 16px;
    padding-bottom: 32px;
  }

  .title {
    color: #e44e35;
    line-height: 25px;
    font-weight: bold;
    font-size: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 12px;
  }
  img {
    width: 80px;
    margin-right: 12px;
    // height: 53px;
  }
  .content {
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 3;
    // overflow: hidden;
    font-size: 12px;
  }
}
</style>